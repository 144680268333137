import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

const Privacy = () => {
  const [activeId, setActiveId] = useState('');
  const articleRef = useRef(null);

  // Define TOC items
  const headings = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'information-we-process', title: 'Information We Process' },
    { id: 'grounds-for-data-processing', title: 'Grounds for Data Processing' },
    { id: 'how-we-use-your-data', title: 'How We Use Your Data' },
    { id: 'data-protection', title: 'Data Protection' },
    { id: 'data-sharing', title: 'Data Sharing' },
    { id: 'data-retention', title: 'Data Retention' },
    { id: 'data-processing-agreement', title: 'Data Processing Agreement' },
    { id: 'your-rights', title: 'Your Rights' },
    { id: 'updates', title: 'Updates to This Statement' }
  ];

  // Improved scroll spy with better tracking
  useEffect(() => {
    const handleScroll = () => {
      if (articleRef.current) {
        const headingElements = articleRef.current.querySelectorAll('h2');
        
        // Get all heading positions
        const headingPositions = Array.from(headingElements).map(heading => ({
          id: heading.id,
          top: heading.getBoundingClientRect().top,
        }));

        // Find the first heading that's either above or below viewport center
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;
        const viewportCenter = scrollPosition + (windowHeight / 3);

        let currentHeading = null;
        let minDistance = Infinity;

        headingPositions.forEach(heading => {
          const headingTop = heading.top + scrollPosition;
          const distance = Math.abs(headingTop - viewportCenter);
          
          if (distance < minDistance) {
            minDistance = distance;
            currentHeading = heading.id;
          }
        });

        if (currentHeading !== activeId) {
          setActiveId(currentHeading);
        }
      }
    };

    // Use requestAnimationFrame for smoother updates
    let ticking = false;
    const scrollHandler = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    handleScroll(); // Initial check
    
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [activeId]);

  // Scroll to heading with offset
  const scrollToHeading = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Helmet>
        <title>Privacy Statement | VerifyPDF</title>
        <meta name="description" content="VerifyPDF Privacy Statement - How we handle and protect your data" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 font-nunito">
        <div className="lg:flex lg:gap-8">
          {/* TOC */}
          <div className="hidden lg:block w-64 flex-shrink-0">
            <div className="sticky top-32">
              <nav>
                <h2 className="text-lg font-semibold mb-4">Table of Contents</h2>
                <ul className="space-y-1">
                  {headings.map((heading, index) => (
                    <li key={heading.id}>
                      <a
                        href={`#${heading.id}`}
                        onClick={(e) => scrollToHeading(e, heading.id)}
                        className={`block text-sm py-2 px-3 rounded-lg relative transition-all duration-200 ${
                          activeId === heading.id
                            ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/30'
                            : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800/30'
                        }`}
                      >
                        <span className="flex items-start gap-2 relative z-10">
                          <span className={`transition-colors duration-200 ${
                            activeId === heading.id
                              ? 'text-blue-500 dark:text-blue-400'
                              : 'text-gray-400 dark:text-gray-500'
                          }`}>
                            {index + 1}
                          </span>
                          <span>{heading.title}</span>
                        </span>
                        {activeId === heading.id && (
                          <motion.div
                            layoutId="activeHeading"
                            className="absolute inset-0 border-2 border-blue-400 dark:border-blue-500 rounded-lg"
                            transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                          />
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <article ref={articleRef} className="flex-1">
            <div className="bg-gradient-to-br from-blue-50 to-white dark:from-blue-950/50 dark:to-gray-900 rounded-2xl shadow-xl p-8 mb-8 border border-blue-100 dark:border-blue-900/50">
              <h1 className="text-4xl font-bold mb-6 font-domine text-gray-900 dark:text-white">Privacy Statement</h1>
              <div className="text-gray-600 dark:text-gray-400">
                Last updated: September 19, 2024
              </div>
            </div>

            {/* Content sections */}
            <div className="prose dark:prose-invert max-w-none space-y-8
              prose-headings:font-domine 
              prose-headings:text-gray-900 dark:prose-headings:text-white
              prose-p:text-gray-700 dark:prose-p:text-gray-300
              prose-p:text-justify
              prose-a:text-blue-600 dark:prose-a:text-blue-400
              prose-strong:text-gray-900 dark:prose-strong:text-white
              prose-li:text-gray-700 dark:prose-li:text-gray-300
              prose-li:text-justify"
            >
              <section id="introduction">
                <h2 className="scroll-mt-32">Article 1. Introduction</h2>
                <p>
                  This website and associated online web application (collectively referred to as "Service") are owned and operated by VerifyPDF LLC, a limited liability company registered in the State of New Mexico, United States of America, with a registered address at 5203 Juan Tabo Blvd STE 2B, Albuquerque, New Mexico 87111, along with its subsidiaries (collectively referred to as "VerifyPDF", "we", or "us"). At VerifyPDF we understand that your privacy is important, and we are committed to protecting your personal data. This Privacy Statement explains how we collect, use, and share your personal information in accordance with the General Data Protection Regulation ("GDPR").
                </p>
                <p>
                  By using the Service, you agree to this Privacy Policy. If you do not agree with this Privacy Policy, you may not use the Service.
                </p>
              </section>

              <section id="information-we-process">
                <h2 className="scroll-mt-32">Article 2. Information We Process</h2>
                <p>
                  We process personal information of users who utilize the Service, and we host documents on our servers that contain sensitive private information, including but not limited to bank statements, payslips and tax filings. This data may include names, contact details, financial information and other personal identifiers.
                </p>
              </section>

              <section id="grounds-for-data-processing">
                <h2 className="scroll-mt-32">Article 3. Grounds for Data Processing</h2>
                <p>We process personal data under the following legal grounds:</p>
                <ul>
                  <li>
                    <strong>Contractual Necessity:</strong> We process personal data as necessary to provide the Service, including managing your registration, allowing you to use the Service and sending tailored communications.
                  </li>
                  <li>
                    <strong>Legitimate Interest:</strong> We process personal data for our legitimate business interests, such as statistical analysis and to fight document fraud. Specifically, we use data to assess the risk of document fraud, which benefits our clients by reducing their exposure to fraudulent activities.
                  </li>
                  <li>
                    <strong>Consent:</strong> In cases where we need specific consent, we will ask for it separately.
                  </li>
                </ul>
              </section>

              <section id="how-we-use-your-data">
                <h2 className="scroll-mt-32">Article 4. How We Use Your Data</h2>
                <p>
                  We use your personal data for various purposes such as providing you access to and send you communications relating to the Service. We may use anonymized data for statistical analysis to improve the Service. We use your data to safeguard and assist you in detecting and preventing document fraud and ensuring the security of our systems.
                </p>
              </section>

              <section id="data-protection">
                <h2 className="scroll-mt-32">Article 5. Data Protection</h2>
                <p>
                  Your data is important to us and we take our role in protecting it very seriously. We are committed to implementing robust security measures and practices to ensure the confidentiality, integrity and availability of our services and your data. Our data environments are hosted within Amazon Web Services ("AWS") in the European Union and are exclusively accessed by EU-based engineers. We ensure no data traverses the internet without industry-standard encryption.
                </p>
                <p>
                  We stay up to date with the latest security patches and are vigilant about system vulnerabilities. We continuously monitor our systems for any potential weaknesses. When vulnerabilities are detected, we act promptly to apply necessary countermeasures. To minimize risks associated with traditional IT infrastructure management, we leverage serverless computing capabilities. Our serverless deployments are automated, further reducing human error and potential exposure points.
                </p>
                <p>
                  In line with the principle of least privilege, we restrict access to data and systems only to those who require it for their specific roles. This targeted approach to data access ensures that only authorized personnel have access to sensitive information. We have strong access control procedures in place to oversee who can access our systems and data. As part of these measures, all of the VerifyPDF staff uses 2-factor authentication to provide an additional layer of security, making it significantly more challenging for unauthorized individuals to gain access.
                </p>
              </section>

              <section id="data-sharing">
                <h2 className="scroll-mt-32">Article 6. Data Sharing</h2>
                <p>
                  We do not share, sell and disclose your personal data to third parties, except as required by law or as necessary to provide the Service. We have appropriate agreements with our clients and service providers to ensure they also adhere to GDPR principles.
                </p>
              </section>

              <section id="data-retention">
                <h2 className="scroll-mt-32">Article 7. Data Retention</h2>
                <p>
                  We retain your personal data for as long as necessary for the purposes of the Service, up to a maximum of 90 days. Upon contract termination or discontinuation of the Service, data will be retained for at most 90 days unless otherwise agreed.
                </p>
                <p>
                  We may retain unstructured data, such as email communication or information received via contact forms, for up to 5 years for legitimate business purposes, including record-keeping and legal compliance. This data is securely stored and managed to prevent unauthorized access.
                  Additionally, we may retain certain data on an anonymized basis for research and development purposes. This helps us improve our services and develop new features. Anonymized data cannot be linked back to specific individuals and is used solely for analytical and development purposes.
                </p>
                <p>
                  Please note that our retention periods may be subject to change as required by law or our legitimate business interests.
                </p>
              </section>

              <section id="data-processing-agreement">
                <h2 className="scroll-mt-32">Article 8. Data Processing Agreement</h2>
                <p>
                  Because VerifyPDF makes it possible to store and use data, we are regarded as a processor and we will process the personal data in a careful manner. We recommend clients to enter into a Data Processing Agreement with VerifyPDF, which defines how we process and protect the data. A draft of the Data Processing Agreement is available upon request.
                </p>
              </section>

              <section id="your-rights">
                <h2 className="scroll-mt-32">Article 9. Your Rights</h2>
                <p>
                  Under GDPR, you have the right to access, rectify, erase, restrict, port and object to the processing of your personal data. To exercise any of these rights or in case of questions or concerns regarding this Privacy Statement or the processing of your personal data, please contact our Data Protection Officer (<a href="mailto:dpo@verifypdf.com">dpo@verifypdf.com</a>).
                </p>
              </section>

              <section id="updates">
                <h2 className="scroll-mt-32">Article 10. Updates to This Statement</h2>
                <p>
                  We may update this Privacy Statement periodically to reflect changes in our practices or applicable laws. Please review this statement regularly to stay informed about how we handle personal data.
                </p>
              </section>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Privacy; 