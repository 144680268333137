import PageTemplate from '../components/PageTemplate';

const Documentation = () => {
  return (
    <PageTemplate
      title="Documentation"
      description="Comprehensive guides and API documentation for VerifyPDF"
    >
      <div className="space-y-8">
        <section>
          <h2 className="text-3xl font-bold mb-4">Getting Started</h2>
          <p className="text-gray-600 dark:text-gray-300">
            Learn how to integrate VerifyPDF into your application.
          </p>
        </section>
        <section>
          <h2 className="text-3xl font-bold mb-4">API Reference</h2>
          <p className="text-gray-600 dark:text-gray-300">
            Detailed API documentation and examples.
          </p>
        </section>
      </div>
    </PageTemplate>
  );
};

export default Documentation; 