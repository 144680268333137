import PageTemplate from '../components/PageTemplate';
import { motion } from 'framer-motion';
import { useState } from 'react';

const Demo = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <PageTemplate
      title="[Insert here the Storylane widget]"
      description="Try VerifyPDF's document verification capabilities with our interactive demo"
    >

    </PageTemplate>
  );
};

export default Demo; 