/**
 * Core configuration constants for VerifyPDF's pricing and FAQ sections
 * 
 * This file contains:
 * - Pricing tier definitions (features, prices, descriptions)
 * - Frequently Asked Questions with answers
 * 
 */

export const tiers = [
    {
       name: 'Basic',
       id: 'tier-basic',
       href: 'https://secure.verifypdf.com/signup',
       priceMonthly: '$19', 
       description: "Perfect for small businesses",
       features: [
         'Up to 150 documents/month',
         '$ 0.15 per extra document',
         'No-code dashboard',
       ],
       featured: false,
     },
     {
       name: 'Professional',
       id: 'tier-professional',
       href: 'https://secure.verifypdf.com/signup',
       priceMonthly: '$99',
       description: 'Ideal for growing companies and startups',
       features: [
         'Up to 1,000 documents/month',
         '$ 0.10 per extra document',
         'No-code dashboard',
         'VerifyPDF API',
         'Priority support',    
       ],
       featured: true,
     },
     {
       name: 'Corporate',
       id: 'tier-corporate',
       href: '/contact',
       priceMonthly: 'Custom',
       description: 'Enterprise-grade solution for large organizations with special requirements',
       features: [
         'Over 1,000 documents/month',
         'Unlimited documents',
         'No-code dashboard',
         'VerifyPDF API',
         'Priority support',
         'Dedicated environment'
       ],
       featured: false,
     }
   ];
   
   export const faqs = [
     {
       question: "How long does it take to verify a document?",
       answer: "Checking a document typically takes 5 seconds, with longer multi-page documents taking up to 30 seconds. Our advanced AI processes multiple verification layers simultaneously (metadata, document structure, content streams, traces of known editors, suspicious elements, etc), providing comprehensive results almost instantly."
     },
     {
       question: "What types of documents can VerifyPDF analyze?",
       answer: "VerifyPDF can analyze any document in PDF format. In particular, we are 99% accurate with computer-generated documents such as bank statements, payslips, tax filings and invoices. We do not process scanned documents, screenshots or identity documents (e.g. passports)."
     },
     {
       question: "What happens if a document is flagged as suspicious?",
       answer: "When a document is flagged as high risk, you will receive a list of indicators with specific areas of concern, including any detected alterations, inconsistencies or signs of manipulation. Our system provides clear explanations and evidence to help you make informed decisions about document authenticity."
     },
     {
       question: "Is my data secure with VerifyPDF?",
       answer: "Yes, we take security seriously. All documents are encrypted in transit and at rest, processed in isolated environments and automatically deleted when the retention period expires. In addition, we are GDPR compliant."
     },
     {
       question: "Can I integrate VerifyPDF into my document review pipelines?",
       answer: "Yes, VerifyPDF offers a robust API that can be integrated into any existing workflow. We provide comprehensive documentation and dedicated support for enterprise integrations."
     },
     {
       question: "Can you handle documents that are not in English?",
       answer: "VerifyPDF detects typical modifications and inconsistencies in PDF documents regardless of their origin. In addition to risk indicators, VerifyPDF has a separate Trusted indicator for known documents we have already verified in our system, such as bank statements from major banks in the US and Europe. We are constantly expanding our Trusted list by adding new financial institutions, payroll providers or invoicing software to name a few examples. If you would like VerifyPDF to verify specific documents in your country or that are relevant to your company's use case, please contact us."
     }
   ]; 