import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'next-themes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Page imports
import Layout from './components/Layout';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Documentation from './pages/Documentation';
import Jobs from './pages/Jobs';
import Training from './pages/Training';
import ApiStatus from './pages/ApiStatus';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Security from './pages/Security';
import Contact from './pages/Contact';
import Demo from './pages/Demo';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Export the App component
export function App() {
  return (
    <Router>
      <HelmetProvider>
        <ThemeProvider attribute="class">
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/about" element={<About />} />
              <Route path="/documentation" element={<Documentation />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/training" element={<Training />} />
              <Route path="/api-status" element={<ApiStatus />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/security" element={<Security />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/demo" element={<Demo />} />
              <Route path="/blog" element={<Blog />} exact />
              <Route path="/404" element={<NotFound />} />
              <Route path="/:slug" element={<BlogPost />} />
              <Route path="*" element={<NotFound />} />              
            </Routes>
          </Layout>
        </ThemeProvider>
      </HelmetProvider>
    </Router>
  );
}

// Default export for App
export default App;
