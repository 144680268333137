import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

const Terms = () => {
  const [activeId, setActiveId] = useState('');
  const articleRef = useRef(null);

  const headings = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'definitions', title: 'Definitions' },
    { id: 'applicability', title: 'Applicability' },
    { id: 'disclaimers', title: 'Disclaimers' },
    { id: 'indemnification', title: 'Indemnification' },
    { id: 'limitation-of-liability', title: 'Limitation of Liability' },
    { id: 'use-of-service', title: 'Use of the Service' },
    { id: 'payment', title: 'Payment' },
    { id: 'cancellation', title: 'Cancellation and Refunds' },
    { id: 'termination', title: 'Termination' },
    { id: 'intellectual-property', title: 'Intellectual Property Rights' },
    { id: 'dispute-resolution', title: 'Dispute Resolution and Governing Law' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (articleRef.current) {
        const headingElements = articleRef.current.querySelectorAll('h2');
        
        const headingPositions = Array.from(headingElements).map(heading => ({
          id: heading.id,
          top: heading.getBoundingClientRect().top,
        }));

        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;
        const viewportCenter = scrollPosition + (windowHeight / 3);

        let currentHeading = null;
        let minDistance = Infinity;

        headingPositions.forEach(heading => {
          const headingTop = heading.top + scrollPosition;
          const distance = Math.abs(headingTop - viewportCenter);
          
          if (distance < minDistance) {
            minDistance = distance;
            currentHeading = heading.id;
          }
        });

        if (currentHeading !== activeId) {
          setActiveId(currentHeading);
        }
      }
    };

    let ticking = false;
    const scrollHandler = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    handleScroll();
    
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [activeId]);

  const scrollToHeading = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Helmet>
        <title>Terms of Service | VerifyPDF</title>
        <meta name="description" content="VerifyPDF Terms of Service - Terms and conditions for using our service" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 font-nunito">
        <div className="lg:flex lg:gap-8">
          {/* TOC */}
          <div className="hidden lg:block w-64 flex-shrink-0">
            <div className="sticky top-32">
              <nav>
                <h2 className="text-lg font-semibold mb-4">Table of Contents</h2>
                <ul className="space-y-1">
                  {headings.map((heading, index) => (
                    <li key={heading.id}>
                      <a
                        href={`#${heading.id}`}
                        onClick={(e) => scrollToHeading(e, heading.id)}
                        className={`block text-sm py-2 px-3 rounded-lg relative transition-all duration-200 ${
                          activeId === heading.id
                            ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/30'
                            : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800/30'
                        }`}
                      >
                        <span className="flex items-start gap-2 relative z-10">
                          <span className={`transition-colors duration-200 ${
                            activeId === heading.id
                              ? 'text-blue-500 dark:text-blue-400'
                              : 'text-gray-400 dark:text-gray-500'
                          }`}>
                            {index + 1}
                          </span>
                          <span>{heading.title}</span>
                        </span>
                        {activeId === heading.id && (
                          <motion.div
                            layoutId="activeHeading"
                            className="absolute inset-0 border-2 border-blue-400 dark:border-blue-500 rounded-lg"
                            transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                          />
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <article ref={articleRef} className="flex-1">
            <div className="bg-gradient-to-br from-blue-50 to-white dark:from-blue-950/50 dark:to-gray-900 rounded-2xl shadow-xl p-8 mb-8 border border-blue-100 dark:border-blue-900/50">
              <h1 className="text-4xl font-bold mb-6 font-domine text-gray-900 dark:text-white">Terms of Service</h1>
              <div className="text-gray-600 dark:text-gray-400">
                Last updated: September 2, 2024
              </div>
            </div>

            {/* Content sections */}
            <div className="prose dark:prose-invert max-w-none space-y-8
              prose-headings:font-domine 
              prose-headings:text-gray-900 dark:prose-headings:text-white
              prose-p:text-gray-700 dark:prose-p:text-gray-300
              prose-p:text-justify
              prose-a:text-blue-600 dark:prose-a:text-blue-400
              prose-strong:text-gray-900 dark:prose-strong:text-white
              prose-li:text-gray-700 dark:prose-li:text-gray-300
              prose-li:text-justify"
            >
              <section id="introduction">
                <h2 className="scroll-mt-32">Article 1. Introduction</h2>
                <p>This website and associated online web application (collectively referred to as "Service") are owned and operated by VerifyPDF LLC, a limited liability company registered in the State of New Mexico, United States of America, with a registered address at 5203 Juan Tabo Blvd STE 2B, Albuquerque, New Mexico 87111, along with its subsidiaries (collectively referred to as "VerifyPDF", "we", or "us"). These Terms and Conditions ("Terms") apply to all Services we provide, whether paid or free. By using our Service, you agree to these Terms, so please read them carefully. They govern your access and use of the Service.</p>
              </section>

              <section id="definitions">
                <h2 className="scroll-mt-32">Article 2. Definitions</h2>
                <p>In these Terms, the following definitions shall apply:</p>
                <ul>
                  <li>"You" refers to the individual or the business entity represented by that individual, using our Service.</li>
                  <li>"Document" refers to a digital file, primarily in PDF format, that you wish to verify via the Service.</li>
                  <li>"User" refers to any individual who accesses, browses, or uses the Service to verify a Document.</li>
                </ul>
              </section>

              <section id="applicability">
                <h2 className="scroll-mt-32">Article 3. Applicability</h2>
                <p>These Terms apply to every use of our Service. By accessing or using this Service, you fully accept these Terms and the associated Privacy Policy, which describes our data collection and handling practices. You agree to provide accurate and reliable information while using our Service. We may update these Terms in the future and will make reasonable efforts to notify you of significant changes. Continued use of the Service after any changes implies acceptance of the updated Terms. If any part of these Terms is found unenforceable, the rest will continue in full force.</p>
              </section>

              <section id="disclaimers">
                <h2 className="scroll-mt-32">Article 4. Disclaimers</h2>
                <p>Please understand that the Service is provided "as is" and "as available," without any warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Any information or advice obtained from VerifyPDF does not create any warranty not explicitly stated in these Terms. VerifyPDF does not warrant that the technical and functional aspects of the Service will be error-free, or that the Service will be constantly available, or that transmission of data to and from the Service will occur at minimum latency or that the servers that make the Service available are free of viruses or other harmful components. Content on this Service or our social media accounts and blogs is for information purposes only and does not constitute legal advice.</p>
              </section>

              <section id="indemnification">
                <h2 className="scroll-mt-32">Article 5. Indemnification</h2>
                <p>You agree to indemnify and hold harmless VerifyPDF and its affiliates, officers, employees, agents, partners and licensors from any claims or demands, including reasonable legal fees, arising from your use of the Service, your violation of these Terms or any law, or your violation of any third-party rights. This indemnification obligation will survive the termination or expiration of these Terms and your use of the Service.</p>
              </section>

              <section id="limitation-of-liability">
                <h2 className="scroll-mt-32">Article 6. Limitation of Liability</h2>
                <p>VerifyPDF is not liable for any direct, indirect, incidental, special, consequential damages, or any other damages, including loss of profits, goodwill, data, or other intangible losses arising from the use or inability to use the Service, even if VerifyPDF has been advised of the possibility of such damages. Our total liability to you for all damages, losses and causes of action shall not exceed EUR 1,000. If you are dissatisfied with the Service or these Terms, your sole remedy is to discontinue using the Service. If any portion of this liability limitation is found to be invalid or unenforceable for any reason, then the aggregate liability of VerifyPDF shall not exceed the amount mentioned above. This limitation applies to the fullest extent permitted by law.</p>
              </section>

              <section id="use-of-service">
                <h2 className="scroll-mt-32">Article 7. Use of the Service</h2>
                <p>VerifyPDF provides businesses and professionals with a document forensics tool to verify the authenticity of documents in PDF format and detect the use of digital manipulation tools or any other tooling or strategy that can be used to commit fraud. The Service is only for business use. Private use is not allowed and may result in termination. You represent that you are at least 18 years old and have the authority to act on behalf of the business entity you represent. The information acquired through the Service shall only be used for detecting document fraud and not provided to third parties without prior written consent. To use the Service, you must register an account with accurate and complete information. You must keep your password confidential and notify us immediately of any unauthorized use of your account or any security breach. You agree not to impersonate anyone or misrepresent your affiliation. By using the Service, you grant us and our subcontractors the authority to use the information you provide to deliver the Service.</p>
                <p>The following terms apply specifically to the single document submission feature titled "Verify one PDF for free": Only one document is allowed per email address. Multiple submissions from the same email address may be rejected or only the first submission may be processed. Only legitimate business email addresses are accepted. We reserve the right to reject submissions from personal email domains or addresses that do not appear to be associated with a business entity. This feature is intended for initial testing and evaluation purposes only. It does not constitute a full subscription to our services. We reserve the right to limit the number of submissions from a single IP address or organization to prevent abuse of this feature. We reserve the right to modify or discontinue this feature at any time without prior notice.</p>
              </section>

              <section id="payment">
                <h2 className="scroll-mt-32">Article 8. Payment</h2>
                <p>After the free trial period, the Service is provided under a monthly subscription fee or, in the case of Corporate customers, any fees specified in a separate contractual agreement. In case of payment via invoice, you agree to pay the price associated with the use of the Service within fifteen (15) calendar days of receiving the invoice or in advance by your preferred payment method. Failure to pay on time may result in suspension or cancellation of access and potential loss of stored content. A billing period is defined as a calendar month. VerifyPDF may change the price of tools and features and will notify you by e-mail at least 30 days before the price change. You must pay all reasonable costs we incur to collect any past-due amounts.</p>
              </section>

              <section id="cancellation">
                <h2 className="scroll-mt-32">Article 9. Cancellation and Refunds</h2>
                <p>Payments are non-refundable unless otherwise stated. When using paid tools and features, you agree that we may begin providing them immediately and you will not be entitled to a cancellation or "cooling off" period unless required by law. If there's an error on your invoice, notify us within 90 days to allow investigation. Failure to do so releases us from liability and claims of loss from the error and no correction or refund will be required.</p>
              </section>

              <section id="termination">
                <h2 className="scroll-mt-32">Article 10. Termination</h2>
                <p>VerifyPDF may terminate your account at any time for breach of your representations, warranties, or obligations under these Terms. Termination does not relieve you of any payment obligations or other agreements not specifically terminated. Upon termination, all licenses and rights granted to you under these Terms will terminate and you must cease all use of the Service.</p>
              </section>

              <section id="intellectual-property">
                <h2 className="scroll-mt-32">Article 11. Intellectual Property Rights</h2>
                <p>VerifyPDF owns the Service and all associated intellectual property rights, trademarks and logos. Unauthorized use, copying, distribution, or creation of derivative works of the Service or its content is prohibited. All trademarks are owned by their respective owners. These Terms do not grant any rights to use VerifyPDF or third-party trademarks, logos, or trade names.</p>
              </section>

              <section id="dispute-resolution">
                <h2 className="scroll-mt-32">Article 12. Dispute Resolution and Governing Law</h2>
                <p>In case of a dispute arising from these Terms or your use of the Service, we encourage you to contact us first to seek a resolution. If the dispute cannot be resolved, it shall be the exclusive jurisdiction of the Dutch courts. These Terms are governed by and construed in accordance with the laws of the Kingdom of the Netherlands.</p>
              </section>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Terms; 