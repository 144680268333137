import { motion } from 'framer-motion';
import PageTemplate from '../components/PageTemplate';
import { 
  DocumentTextIcon, 
  UserGroupIcon, 
  ShieldExclamationIcon,
  BoltIcon,
  ShieldCheckIcon,
  BuildingLibraryIcon,
  CurrencyDollarIcon,
  BuildingOfficeIcon,
  TruckIcon,
  HomeIcon,
  ScaleIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';


const About = () => {
  const [currentObjective, setCurrentObjective] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentObjective((prev) => (prev + 1) % objectives.length);
    }, 7000);

    return () => clearInterval(timer);
  }, []);

  const objectives = [
    {
      number: "1",
      title: "Create a fraud-free world",
      description: "We envision a future where document fraud is impossible - where every business transaction is built on an unshakeable foundation of trust and transparency.",
    },
    {
      number: "2", 
      title: "Empower honest businesses",
      description: "We are building technology that levels the playing field, ensuring honest businesses and customers can thrive without being undermined by fraudulent actors.",
    },
    {
      number: "3",
      title: "Enable safe AI automation",
      description: "We are unlocking the full potential of AI automation by providing the essential trust layer, ensuring that automated processes can be implemented safely without compromising security.",
    }
  ];

  return (
    <PageTemplate>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative pt-16 pb-12 px-4 sm:px-6 lg:px-8 overflow-hidden"
        >
          <div className="relative z-10 max-w-7xl mx-auto text-center">
            <motion.h1 
              className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 mt-4 sm:mt-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Automation without fraud detection is<br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700 animate-gradient font-black">
                simply reckless
              </span>
            </motion.h1>

            <motion.p
              className="font-['Nunito'] mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              VerifyPDF was founded with a mission: fighting document fraud in an increasingly digital world. Businesses face the dual challenge of serving customers efficiently while fighting against fraud. Traditional manual reviews are not going to cut it with today's widespread access to free PDF editors and fraudulent templates available online. That is where we come in.
            </motion.p>
          </div>
        </motion.section>

        {/* Objectives Section */}
        <section className="relative py-16 bg-white dark:bg-gray-900">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <motion.h2 
              className="mt-2 font-['Domine'] text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl text-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              This is what we believe in
            </motion.h2>

            <div className="relative max-w-4xl mx-auto">
              <motion.div 
                className="overflow-hidden rounded-2xl"
                initial={false}
              >
                <motion.div
                  className="flex"
                  animate={{ x: `${-currentObjective * 100}%` }}
                  transition={{ 
                    type: "spring", 
                    stiffness: 200, 
                    damping: 35, 
                    duration: 1
                  }}
                >
                  {objectives.map((objective, index) => (
                    <motion.div
                      key={objective.number}
                      className="min-w-full p-8"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: currentObjective === index ? 1 : 0.3 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-xl border border-gray-100 dark:border-gray-700">
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center gap-4 mb-2">
                            <span className="text-5xl font-black text-primary-500/20 dark:text-primary-400/20">
                              {objective.number}
                            </span>
                            <h4 className="font-['Domine'] text-2xl font-bold text-gray-900 dark:text-white">
                              {objective.title}
                            </h4>
                          </div>
                          <p className="font-['Nunito'] text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                            {objective.description}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>



        {/* Use Cases Section */}
        <section className="relative py-16 bg-white dark:bg-gray-900">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center mb-12">
              <motion.h2 
                className="mt-2 font-['Domine'] text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl text-center"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                VerifyPDF fights fraud across multiple industries
              </motion.h2>
            </div>

            <motion.div 
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              {[
                {
                  title: "Lending",
                  description: "The classical moral hazard problem. Bad actors routinely misrepresent or fabricate their income to get loans or credit cards.",
                  icon: CurrencyDollarIcon,
                  gradient: "from-blue-500 to-cyan-500"
                },
                {
                  title: "Insurance",
                  description: "People might present fake documents to support inflated or entirely false claims, driving up premiums for everyone.",
                  icon: ShieldCheckIcon,
                  gradient: "from-purple-500 to-pink-500"
                },
                {
                  title: "Employers",
                  description: "Companies risk reimbursing fraudulent expense claims or hiring based on fabricated credentials.",
                  icon: BuildingOfficeIcon,
                  gradient: "from-orange-500 to-yellow-500"
                },
                {
                  title: "Auto Leasing",
                  description: "Individuals may alter proof of income documents to secure high-value vehicles beyond their means.",
                  icon: TruckIcon,
                  gradient: "from-green-500 to-emerald-500"
                },
                {
                  title: "Real Estate",
                  description: "Applicants submit manipulated documents to qualify for rentals, leading to costly eviction processes.",
                  icon: HomeIcon,
                  gradient: "from-red-500 to-orange-500"
                },
                {
                  title: "Legal",
                  description: "Legal professionals may unknowingly base decisions on fake documents during litigation or M&A.",
                  icon: ScaleIcon,
                  gradient: "from-indigo-500 to-purple-500"
                }
              ].map((useCase, index) => (
                <motion.div
                  key={index}
                  className="group relative aspect-square"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="absolute inset-0 rounded-3xl bg-gradient-to-br opacity-10 dark:opacity-20 group-hover:opacity-15 dark:group-hover:opacity-30 transition-opacity duration-300"
                    style={{ backgroundImage: `linear-gradient(to bottom right, var(--tw-gradient-stops))` }}
                  />
                  <div className="relative h-full p-6 rounded-3xl bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm border border-gray-200 dark:border-gray-700 hover:border-gray-300 dark:hover:border-gray-600 transition-all duration-300">
                    <div className="h-full flex flex-col items-center justify-start text-center p-4">
                      <motion.div 
                        className={`rounded-full p-4 bg-gradient-to-br ${useCase.gradient} mb-6 mt-4`}
                        whileHover={{ rotate: 360 }}
                        transition={{ duration: 0.8 }}
                      >
                        <useCase.icon 
                          className="h-8 w-8 text-white"
                          aria-hidden="true" 
                        />
                      </motion.div>
                      
                      <h3 className="font-['Domine'] text-2xl font-bold text-gray-900 dark:text-white mb-4">
                        {useCase.title}
                      </h3>
                      
                      <p className="font-['Nunito'] text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                        {useCase.description}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>

      </div>
    </PageTemplate>
  );
};

export default About; 