import { useState, useEffect, useRef } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { getPostData } from '../utils/blogUtils';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Helmet } from 'react-helmet-async';
import { motion, AnimatePresence } from 'framer-motion';
import CTA from '../components/CTA';

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [activeId, setActiveId] = useState('');
  const articleRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => { 
    const loadPost = async () => {
      try {
        setIsLoading(true);
        setIsVisible(false);
        setPost(null);
        
        const postData = await getPostData(slug);
        
        if (!postData) {
          navigate('/404', { replace: true });
          return;
        }

        const processedPost = {
          ...postData,
          tags: Array.isArray(postData.tags) ? postData.tags : []
        };

        setPost(processedPost);
        setIsLoading(false);
        
        setTimeout(() => setIsVisible(true), 2000);
        
        window.scrollTo(0, 0);
      } catch (err) {
        console.error('Error loading post:', err);
        navigate('/404', { replace: true });
      }
    };

    loadPost();
  }, [slug, navigate]);

  // Extract headings from markdown content
  useEffect(() => {
    if (post?.content) {
      const extractHeadings = (content) => {
        const headingRegex = /^##\s+(.+)$/gm;
        const matches = [...content.matchAll(headingRegex)];
        return matches.map(match => ({
          id: match[1].toLowerCase().replace(/[^a-z0-9]+/g, '-'),
          title: match[1],
        }));
      };
      setHeadings(extractHeadings(post.content));
    }
  }, [post]);

  // Improved scroll spy with better tracking
  useEffect(() => {
    const handleScroll = () => {
      if (articleRef.current) {
        const headingElements = articleRef.current.querySelectorAll('h2');
        
        // Get all heading positions
        const headingPositions = Array.from(headingElements).map(heading => ({
          id: heading.id,
          top: heading.getBoundingClientRect().top,
        }));

        // Find the first heading that's either above or below viewport center
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;
        const viewportCenter = scrollPosition + (windowHeight / 3);

        let currentHeading = null;
        let minDistance = Infinity;

        headingPositions.forEach(heading => {
          const headingTop = heading.top + scrollPosition;
          const distance = Math.abs(headingTop - viewportCenter);
          
          if (distance < minDistance) {
            minDistance = distance;
            currentHeading = heading.id;
          }
        });

        if (currentHeading !== activeId) {
          setActiveId(currentHeading);
        }
      }
    };

    // Use requestAnimationFrame for smoother updates
    let ticking = false;
    const scrollHandler = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    handleScroll(); // Initial check
    
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [activeId]);

  // Scroll to heading with offset
  const scrollToHeading = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  // Helper function to clean tag format
  const formatTag = (tag) => {
    return tag.replace(/[\[\]"]/g, '').trim();
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <AnimatePresence mode="wait">
        {(isLoading || !post) ? (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32"
          >
            {/* Loading Skeleton */}
            <div className="space-y-8">
              <div className="relative h-12 bg-gray-200 dark:bg-gray-800 rounded-lg overflow-hidden">
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent"
                  animate={{
                    x: ['-100%', '100%'],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 1.5,
                    ease: "linear",
                  }}
                />
              </div>
              {[1, 2, 3].map((i) => (
                <div key={i} className="space-y-3">
                  <div className="relative h-4 bg-gray-200 dark:bg-gray-800 rounded w-full overflow-hidden">
                    <motion.div
                      className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent"
                      animate={{
                        x: ['-100%', '100%'],
                      }}
                      transition={{
                        repeat: Infinity,
                        duration: 1.5,
                        ease: "linear",
                        delay: i * 0.2,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 font-nunito"
          >
            <div className="lg:flex lg:gap-8">
              {/* Numbered TOC with tracking */}
              <div className="hidden lg:block w-64 flex-shrink-0">
                <div className="sticky top-32">
                  <nav>
                    <h2 className="text-lg font-semibold mb-4">Table of Contents</h2>
                    <ul className="space-y-1">
                      {headings.map((heading, index) => (
                        <li key={heading.id}>
                          <a
                            href={`#${heading.id}`}
                            onClick={(e) => scrollToHeading(e, heading.id)}
                            className={`block text-sm py-2 px-3 rounded-lg relative transition-all duration-200 ${
                              activeId === heading.id
                                ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/30'
                                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800/30'
                            }`}
                          >
                            <span className="flex items-start gap-2 relative z-10">
                              <span className={`text-sm transition-colors duration-200 ${
                                activeId === heading.id
                                  ? 'text-blue-500 dark:text-blue-400'
                                  : 'text-gray-400 dark:text-gray-500'
                              }`}>
                                {index + 1}
                              </span>
                              <span>{heading.title}</span>
                            </span>
                            {activeId === heading.id && (
                              <motion.div
                                layoutId="activeHeading"
                                className="absolute inset-0 border-2 border-blue-400 dark:border-blue-500 rounded-lg"
                                transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                              />
                            )}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>

              {/* Main Content */}
              <article ref={articleRef} className="flex-1">
                <div className="bg-gradient-to-br from-blue-50 to-white dark:from-blue-950/50 dark:to-gray-900 rounded-2xl shadow-xl p-8 mb-8 border border-blue-100 dark:border-blue-900/50">
                  <div className="flex flex-wrap gap-2 mb-6">
                    {post.tags.map((tag) => (
                      <span 
                        key={tag} 
                        className="px-3 py-1 bg-blue-100 dark:bg-blue-900/50 rounded-md text-sm text-blue-800 dark:text-blue-200"
                      >
                        {formatTag(tag)}
                      </span>
                    ))}
                  </div>
                  <h1 className="text-4xl font-bold mb-6 font-domine">{post.title}</h1>
                  <div className="flex items-center gap-6 text-gray-600 dark:text-gray-400">
                    <time>{new Date(post.date).toLocaleDateString('en-US', { 
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })}</time>
                    <span>{post.readingTime}</span>
                  </div>
                </div>

                <div className="backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 rounded-2xl shadow-xl p-8 border dark:border-gray-700/20">
                  <div className="prose dark:prose-invert max-w-none
                    prose-headings:font-domine
                    prose-p:text-gray-700 dark:prose-p:text-gray-300
                    prose-a:text-blue-600 dark:prose-a:text-blue-400
                    prose-strong:text-gray-900 dark:prose-strong:text-gray-100
                    prose-code:text-gray-800 dark:prose-code:text-gray-200
                    prose-pre:bg-gray-900 dark:prose-pre:bg-gray-800
                    prose-pre:text-gray-100 dark:prose-pre:text-gray-200
                    prose-ul:text-gray-700 dark:prose-ul:text-gray-300
                    prose-ol:text-gray-700 dark:prose-ol:text-gray-300
                    prose-li:marker:text-gray-400 dark:prose-li:marker:text-gray-500"
                  >
                    <ReactMarkdown 
                      remarkPlugins={[remarkGfm]}
                      components={{
                        h2: ({node, children, ...props}) => {
                          const id = children.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-');
                          return (
                            <h2 
                              id={id}
                              className="scroll-mt-32" 
                              {...props}
                            >
                              {children}
                            </h2>
                          );
                        },
                      }}
                    >
                      {post.content}
                    </ReactMarkdown>
                  </div>
                </div>
              </article>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Only show CTA when content is visible */}
      {isVisible && <CTA />}

      {/* Only render Helmet when post data is available */}
      {post && (
        <Helmet>
          <title>{post.title} | Your Site Name</title>
          <meta name="description" content={post.excerpt} />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.excerpt} />
          <meta property="og:image" content={post.coverImage} />
          <meta property="og:type" content="article" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={post.title} />
          <meta name="twitter:description" content={post.excerpt} />
          <meta name="twitter:image" content={post.coverImage} />
          <link rel="canonical" href={`https://yoursite.com/${post.slug}`} />
        </Helmet>
      )}
    </div>
  );
};

export default BlogPost; 