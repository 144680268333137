import { motion } from 'framer-motion';
import PageTemplate from '../components/PageTemplate';
import { 
  ShieldCheckIcon, 
  LockClosedIcon, 
  ServerIcon, 
  ClockIcon,
  KeyIcon,
  DocumentCheckIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const SecurityFeature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center h-full p-6 pt-10 rounded-2xl bg-gradient-to-br from-blue-500/5 to-blue-500/10 dark:from-blue-500/5 dark:to-blue-500/10 backdrop-blur-sm border border-blue-500/10 dark:border-blue-400/10">
    <div className="mb-4 rounded-xl bg-blue-100 dark:bg-blue-900/30 p-4">
      <Icon className="h-8 w-8 text-blue-600 dark:text-blue-400" aria-hidden="true" />
    </div>
    <h3 className="font-['Nunito'] font-semibold text-gray-900 dark:text-white text-xl mb-3 text-center">
      {title}
    </h3>
    <p className="font-['Nunito'] text-base text-gray-600 dark:text-gray-300 leading-relaxed text-center">
      {description}
    </p>
  </div>
);

const Security = () => {
  return (
    <PageTemplate>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative pt-16 pb-20 px-4 sm:px-6 lg:px-8 overflow-hidden"
        >
          <div className="relative z-10 max-w-7xl mx-auto text-center">
            <motion.h1 
              className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 mt-4 sm:mt-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Enterprise-grade security <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700 animate-gradient font-black">
                without compromise
              </span>
            </motion.h1>

            <motion.p
              className="font-['Nunito'] mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              Your security is our top priority. We implement bank-grade security measures 
              and follow industry best practices to ensure your sensitive documents are protected.
            </motion.p>
          </div>
        </motion.section>

        {/* Key Security Features */}
        <section className="relative py-16 bg-white dark:bg-gray-900">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <SecurityFeature
                icon={ShieldCheckIcon}
                title="Zero-Trust architecture"
                description="Data is fully encrypted both at rest and in transit, ensuring maximum protection. Only authorized users can access sensitive information."
              />
              <SecurityFeature
                icon={KeyIcon}
                title="Advanced access controls"
                description="Our engineers use multi-factor authentication, passkeys and granular role-based access control. Every access attempt is logged and monitored in real-time."
              />
              <SecurityFeature
                icon={ServerIcon}
                title="Cloud infrastructure"
                description="Powered by AWS's infrastructure with industry-leading security standards and compliance certifications. All instances are in the eu-central (Frankfurt) datacenter."
              />
            </dl>
          </div>
        </section>

        {/* Corporate Security Section - remains mostly the same but with enhanced styling */}
        <section className="relative bg-gradient-to-b from-gray-50 to-white dark:from-gray-800/50 dark:to-gray-900 rounded-2xl p-8 my-16">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 className="font-['Domine'] text-3xl font-bold mb-8 text-gray-900 dark:text-white text-center">
              Enhanced security for Corporate customers
            </h2>
            <div className="space-y-6 text-gray-600 dark:text-gray-300">
              <p className="font-['Nunito'] mt-6 text-pretty text-base leading-7 text-gray-600 dark:text-gray-300 text-center max-w-3xl mx-auto">
                Our Corporate plan includes additional security features designed to meet the most stringent procurement requirements. This enables us to serve financial institutions, law firms and other enterprises.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                {[
                  "Dedicated environment with complete isolation",
                  "Custom data retention policies",
                  "Detailed security documentation",
                  "Right to security audits and penetration testing",
                  "Custom security controls implementation",
                  "Priority access to security team",
                  "Comprehensive audit logs",
                  "Advanced threat monitoring and alerts"
                ].map((feature, index) => (
                  <div
                    key={index}
                    className="relative p-5 rounded-xl bg-gradient-to-br from-white to-blue-50 dark:from-gray-800 dark:to-gray-800/50 border border-blue-100 dark:border-blue-900/50 shadow-sm hover:shadow-md transition-all duration-300 backdrop-blur-sm group"
                  >
                    <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-blue-500/[0.02] to-blue-500/[0.08] dark:from-blue-400/[0.02] dark:to-blue-400/[0.08] opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    
                    <div className="relative flex items-start space-x-3">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-gradient-to-r from-green-500 to-emerald-400 dark:from-green-400 dark:to-emerald-300 flex items-center justify-center shadow-sm">
                        <svg
                          className="w-3.5 h-3.5 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-base font-medium text-gray-700 dark:text-gray-200">
                        {feature}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <p className="font-['Nunito'] mt-6 text-pretty text-base leading-7 text-gray-600 dark:text-gray-300 text-center">
                Contact our sales team to learn more about our enterprise security capabilities and how we can meet your specific requirements. However, we currently do not have a HIPAA compliance certification and cannot offer services to healthcare providers handling protected health information.
              </p>
              
              <div className="mt-8 flex justify-center pb-16">
                <Link
                  to="/contact"
                  className="no-underline rounded-md bg-primary-500 text-white shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500 px-3.5 py-2.5 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Contact sales
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageTemplate>
  );
};

export default Security; 